import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_vm._m(0), _c("div", {
    staticClass: "sitemap__list p-5"
  }, [_c("div", {
    staticClass: "sitemap__filter d-flex mb-3"
  }, [_c("select-input", {
    staticClass: "mr-2",
    style: {
      width: "150px"
    },
    attrs: {
      "placeholder": "Select Country",
      "reference": "identities.applications.contexts",
      "custom-query": "app=fas&entityLevel=country",
      "source": "entityLevelCode",
      "source-label": "entityLevelName",
      "value": _vm.countryCode
    },
    on: {
      "change": _vm.onCountryChange
    }
  }), _c("select-input", {
    staticClass: "mr-2",
    style: {
      width: "150px"
    },
    attrs: {
      "placeholder": "Select Facility",
      "rules": "required",
      "data-source": _vm.listFacilities,
      "source": "entityLevelCode",
      "source-label": "entityLevelName",
      "value": _vm.facilityCode
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("facilityCode", $event);
      }
    }
  }), _c("select-input", {
    staticClass: "mr-2",
    style: {
      width: "200px"
    },
    attrs: {
      "rules": "required",
      "reference": "fas.financials",
      "source": "year",
      "placeholder": "Select Financial Year",
      "value": _vm.year
    },
    on: {
      "change": function($event) {
        return _vm.onFilterChange("year", $event);
      }
    }
  }), _c("a-button", {
    attrs: {
      "icon": "close-circle"
    },
    on: {
      "click": _vm.onClearFilter
    }
  }, [_vm._v(" Clear Filter ")])], 1), _c("a-list", {
    attrs: {
      "grid": {
        gutter: 18,
        md: 2,
        lg: 4
      },
      "data-source": _vm.listSiteMap
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function(item) {
        return _c("a-list-item", {}, [_c("a-card", {
          staticClass: "sitemap-card",
          attrs: {
            "body-style": {
              padding: "12px"
            }
          }
        }, [_c("div", {
          staticClass: "d-flex"
        }, [_c("div", [_c("upload-input", {
          style: {
            marginBottom: "-14px"
          },
          attrs: {
            "type": "path",
            "upload-type": "multiFile",
            "file-list": [Object.assign({}, item, {
              name: item.path,
              url: _vm.apiUrl + item.path,
              response: {
                path: item.path
              }
            })],
            "label": "Image",
            "max-file-size": 10,
            "max-list-length": 1,
            "disabled": true,
            "confirm-remove-content": "Are you sure that you want to delete the selected attachment?"
          }
        })], 1), _c("div", {
          staticClass: "sitemap-card__info d-flex flex-column justify-content-center neutral-10--text mr-2"
        }, [_c("p", [_c("a-icon", {
          staticClass: "mr-1",
          attrs: {
            "type": "environment"
          }
        }), _vm._v(" " + _vm._s(item.countryName) + " ")], 1), _c("p", [_c("a-icon", {
          staticClass: "mr-1",
          attrs: {
            "type": "user"
          }
        }), _vm._v(" " + _vm._s(item.facilityName) + " ")], 1), _c("p", {
          staticClass: "mb-0"
        }, [_c("a-icon", {
          staticClass: "mr-1",
          attrs: {
            "type": "calendar"
          }
        }), _vm._v(" " + _vm._s(item.year) + " ")], 1)])])])], 1);
      }
    }])
  }), _c("a-pagination", {
    staticClass: "d-flex justify-content-end mb-5",
    attrs: {
      "show-size-changer": "",
      "current": _vm.currentPage,
      "total": _vm.total,
      "page-size": _vm.pageSize
    },
    on: {
      "showSizeChange": _vm.onPageSizeChange,
      "change": _vm.onPageChange
    }
  })], 1)]);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "sitemap__header px-5 pt-2 pb-3"
  }, [_c("h2", {
    staticClass: "m-0"
  }, [_vm._v(" Sitemap ")])]);
}];
var Sitemap_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "Sitemap",
  inject: ["crud", "apiUrl"],
  data() {
    return {
      listFacilities: [],
      countryCode: void 0,
      facilityCode: void 0,
      year: void 0
    };
  },
  computed: {
    listSiteMap() {
      return this.crud.getList();
    },
    total() {
      return this.crud.getPagination().count;
    },
    currentPage() {
      return this.crud.getPagination().page;
    },
    pageSize() {
      return this.crud.getPagination().pageSize;
    }
  },
  async mounted() {
    this.crud.deleteFilter("IsInactive");
    await this.crud.fetchList();
  },
  methods: {
    async onFilterChange(key, value) {
      this.crud.setQueryString(key, value);
      switch (key) {
        case "countryCode":
          this.countryCode = value;
          break;
        case "facilityCode":
          this.facilityCode = value;
          break;
        case "year":
          this.year = value;
          break;
      }
      await this.crud.fetchList();
    },
    async getListFacilities(countryCode) {
      const { data } = await this.axios.get(`${this.apiUrl}/identities/applications/contexts?app=fas&entityLevel=facility&entityParentCode=${countryCode}`);
      this.listFacilities = data;
    },
    onCountryChange(value) {
      this.onFilterChange("countryCode", value);
      this.getListFacilities(value);
    },
    onPageChange(value) {
      this.crud.setPagination({ page: value });
      this.crud.fetchList();
    },
    onPageSizeChange(current, pageSize) {
      this.crud.setPagination({ page: current, pageSize });
    },
    onClearFilter() {
      this.crud.deleteQueryStrings();
      this.countryCode = void 0;
      this.facilityCode = void 0;
      this.year = void 0;
      this.listFacilities = [];
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "43513291", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Sitemap = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.financials",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "identities.applications.contexts",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.assetregisters.sitemapscreen",
      "api-url": _vm.apiUrl
    }
  }, [_c("sitemap")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { Sitemap },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "sitemap",
          title: "Sitemap",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
